import callingApi from '../../services/apiService';
import { apiUrl } from '../../constants/api-urls';
import { BulkQualification as BKActionTypes, AddressQualification as ActionTypes } from '../../constants/actionTypes';
import { catchJSException, logMetrics } from '../../services/logService';
import { showLoader, hideLoader } from '../common/loader/actions';
import { LQ_VIEWS, MethodType, Flowtype } from '../../constants/common';
import { updateCurrentView, setCheckAvailBtnTrack, updateSkeleton } from '../loop-qual-app/actions';
import { lqServices } from '../../constants/lq-constants';
import { checkNetworkAvailability, singlefromBulkQualUpdate, lqsTagTriggered } from '../address-qualification/actions';
import { orderNowFiveGNsa } from '../order-fiveg-home/actions';
import { sendDataVzTagLqa, sendDataVzTagLqs } from '../../utils/unifiedVzTag';
import { isWifiBackupMLP, setToStorage, wifiBackupFromFios, wifiBackupQueryParam } from '../../utils/lqServiceUtil';
import { closeModalWindow } from '../common/modal/action';
import { setAddressDetail } from '../address-lookup/actions';

const params = new URLSearchParams(window.location.search);

export const checkLoggedInBulkQual = async (
  dispatch,
  loopQualApp,
  fiosDetails,
  addressQualification,
  customerStreetDetails,
  unitLookupDetails,
  moveDetails,
  cookies,
  isRetry = false,
  bulkQualDetails,
) => {
  let isUpdateBulk = false;
  try {
    const scenario = ['FIOS_QUALIFIED', '5G_HOME_QUALIFIED', 'LTE_HOME_QUALIFIED', '5G_CBAND_HOME_QUALIFIED'];
    const isQualified = scenario.includes(sessionStorage.getItem('LqQualified'));
    if (!isQualified) {
      dispatch(updateCurrentView(LQ_VIEWS.BLANK_LOADER_VIEW));
    }
    isUpdateBulk = true;
    dispatch(updateSkeleton(true));
    const url = apiUrl().fetchAuthDetailsBulk;
    dispatch(showLoader());
    let flowName = '';
    if (loopQualApp?.sourceInfo?.toUpperCase() === 'AALCOMBO') {
      flowName = `SEQ_MH_MAQ`;
    }
    if (loopQualApp?.isECComboMFJT) {
      flowName = `EC_JT_MH|${flowName}`;
    }
    const requiredflowType = { flowType: flowName };
    const tgwFlow =
      window.location.search && (window.location.search.indexOf('fcltgw=y') > -1 || window.location.search.indexOf('FCLTGW=Y') > -1) ? 'Y' : null;
    const data = {};
    const EnableWifiBackup = loopQualApp?.runTimeKey?.EnableWifiBackup === 'Y';
    if (wifiBackupQueryParam || EnableWifiBackup) {
      data.wifiBackupRequired = true;
    }
    if (wifiBackupFromFios && isWifiBackupMLP) {
      data.wifiBackupRequired = true;
      data.wifiBackupOnly = true;
      data.isFiosCustomer = true;
    }
    if (params && params?.get('userId')) {
      data.userId = params?.get('userId');
    }
    if (tgwFlow) {
      data.tanglewoodQualified = 'Y';
    }
    const isMovers = loopQualApp?.isLoggedIn && loopQualApp?.flowType === Flowtype.MOVERS;
    if (loopQualApp?.isLoggedIn && !isMovers) {
      data.newQualFlow = true;
    }
    if (loopQualApp.isACPFlow) {
      data.isAcp = true;
    }
    if (loopQualApp.isECComboMFJT) {
      data.suppressFiosForJointTransaction = true;
    }
    const updatedData = params?.get('loggedInGeo') ? await getGeolocationData(data) : data;
    return new Promise((resolve, reject) => {
      callingApi(url, MethodType.POST, updatedData, 'application/json', requiredflowType)
        .then((response) => {
          // setToStorage('LQBulkResponse', JSON.stringify(response));
          setToStorage('ResponsiveLQBulkRes', 'Y');
          let validError = false;
          let isBulkQualOrder = false;
          if (response && response.data && response.data.errors && response.data.errors[0] && response.data.errors[0].id) {
            const eId = response.data.errors[0].id;
            if (eId.indexOf('3110') > -1 || eId.indexOf('3111') > -1 || eId.indexOf('3119') > -1) {
              validError = true;
            }
          }
          if (response && response.data && response.data.output && response.data.output.qualifiedAddressList) {
            setToStorage('LQBulkResponse', JSON.stringify(response));
            if (response.data.output.digitalSessionId) {
              window.sessionStorage.setItem('digitalSessionId', response.data.output.digitalSessionId);
            }
            logMetrics('checkLoggedInBulk', JSON.stringify(response), 'Info');
            try {
              let qualifedlist = '';
              for (let i = 0; i <= response.data.output.qualifiedAddressList.length - 1; i++) {
                if (response.data.output.qualifiedAddressList[i].networkBandwidthType === 'cbd') {
                  qualifedlist += '| cbd';
                } else if (response.data.output.qualifiedAddressList[i].networkBandwidthType === 'mmw') {
                  qualifedlist += '| mmw';
                } else if (response.data.output.qualifiedAddressList[i].fiosQualified) {
                  qualifedlist += '| fios';
                } else if (response.data.output.qualifiedAddressList[i].qualified4GHome) {
                  qualifedlist += '| lte';
                }
                if (response.data.output.qualifiedAddressList[i]?.existingFWA) {
                  isFwaExisting(dispatch, true);
                }
              }
              setQualifiedlist(dispatch, qualifedlist);
              console.log('qualifedlist', qualifedlist);
              if (loopQualApp.isACPFlow && response.data.output?.acpInfo) {
                dispatch({ type: ActionTypes.SC_ACP, value: response.data.output.acpInfo?.isAcpExist });
                dispatch({ type: ActionTypes.SC_VFP, value: response.data.output.acpInfo?.isVfExist });
                window.sessionStorage.setItem('isVfExist', response.data.output.acpInfo?.isVfExist);
                window.sessionStorage.setItem('isAcpExist', response.data.output.acpInfo?.isAcpExist);
                if (response.data.output.acpInfo?.customerName) {
                  const details = {
                    firstName: response.data.output.acpInfo.customerName?.firstName,
                    lastName: response.data.output.acpInfo.customerName?.lastName,
                  };
                  dispatch({ type: ActionTypes.SC_DETAILS, value: details });
                }
              }
            } catch (e) {
              console.log('error in setting service qualifed');
            }
            dispatch({ type: BKActionTypes.PROFILE_BULK_QUALIFICATION_DETAILS, value: response.data.output });
            if (
              loopQualApp?.isACPFlow &&
              loopQualApp?.isLoggedIn &&
              response.data.output?.existingFWA &&
              window.sessionStorage.getItem('isVfEmail') === 'true' &&
              ((window.sessionStorage.getItem('isVfExist') === 'true' && window.sessionStorage.getItem('isVfNYExist') === 'false') ||
                window.sessionStorage.getItem('isVfExist') === 'false') &&
              window.sessionStorage.getItem('fromAddressQual') !== 'true'
            ) {
              dispatch(updateCurrentView(LQ_VIEWS.VF_QUAL_FWA));
            } else if (
              loopQualApp?.isACPFlow &&
              loopQualApp?.isLoggedIn &&
              response.data.output.acpInfo.isVfExist === true &&
              response.data.output.acpInfo.isVfNyExist === false &&
              response.data.output.billingAddress.state === 'NY'
            ) {
              dispatch(updateCurrentView(LQ_VIEWS?.VFNY_DISCOUNT));
            } else if (loopQualApp?.isACPFlow && loopQualApp?.isLoggedIn && response.data.output.customerInfo.customerType !== 'PE') {
              dispatch(updateCurrentView(LQ_VIEWS?.VF_Employee_NotEligible));
            } else if (
              loopQualApp.isACPFlow &&
              loopQualApp?.isLoggedIn &&
              response.data.output?.existingFWA &&
              window.sessionStorage.getItem('fromAddressQual') !== 'true' &&
              loopQualApp?.isVfEc &&
              response.data.output.customerInfo.customerType === 'PE' &&
              window.sessionStorage.getItem('isVfExist') === 'false'
            ) {
              dispatch(updateCurrentView(LQ_VIEWS.VF_QUESTIONAIRE));
            } else if (response.data.output.qualifiedAddressList && response.data.output.qualifiedAddressList.length > 1) {
              dispatch(updateCurrentView(LQ_VIEWS.BULK_SERVICES_QUALIFIED_VIEW));
              setCheckAvailBtnTrack('multiline order now bulk', dispatch);
              sendDataVzTagLqa('multi');
            } else if (response.data.output.qualifiedAddressList && response.data.output.qualifiedAddressList.length === 1) {
              const bulk_Service = getBulkService(response.data.output.qualifiedAddressList[0]);
              isBulkQualOrder = true;
              setPreferredBulkService(dispatch, bulk_Service, response.data.output.qualifiedAddressList[0]);
              const qualifiedResponse = response.data.output.qualifiedAddressList[0];
              if (qualifiedResponse.uberPinEligible || qualifiedResponse.fiosQualified) {
                isUpdateBulk = false;
              }
              setTimeout(() => {
                bulkQualOrderingIntent(
                  dispatch,
                  response.data.output.qualifiedAddressList[0],
                  loopQualApp,
                  addressQualification,
                  unitLookupDetails,
                  fiosDetails,
                  moveDetails,
                  cookies,
                  false,
                  response.data.output.qualifiedAddressList[0],
                );
              }, 2000);
              let checkAvailBtnTrack = '';
              const qualifedlist = response.data.output.qualifiedAddressList[0];
              if (qualifedlist.qualifiedCBand) {
                checkAvailBtnTrack = 'order now - 5g eligible -cband|AAL';
              } else if (qualifedlist.qualified) {
                checkAvailBtnTrack = 'order now - 5g eligible -mmWave|AAL';
              } else if (qualifedlist.qualified4GHome) {
                checkAvailBtnTrack = 'order now - lte eligible|AAL';
              } else if (qualifedlist.fiosQualified) {
                checkAvailBtnTrack = 'order now - fios eligible|AAL';
              }
              setCheckAvailBtnTrack(checkAvailBtnTrack, dispatch);
              sendDataVzTagLqa('single');
            } else if (
              response.data.output.billingAddress &&
              response.data.output.billingAddress.bulkQualAddressType &&
              response.data.output.billingAddress.bulkQualAddressType === 'BillAccount'
            ) {
              singlefromBulkQualUpdate(dispatch, response.data.output.billingAddress, loopQualApp, fiosDetails);
              if (
                response.data.output.billingAddress.networkBandwidthType &&
                !(
                  response.data.output.billingAddress.fiosQualified ||
                  response.data.output.billingAddress.qualifiedCBand ||
                  response.data.output.billingAddress.qualified4GHome ||
                  response.data.output.billingAddress.qualified
                )
              ) {
                isFwaExisting(dispatch, true);
                dispatch(updateCurrentView(LQ_VIEWS.ADDRESS_QUALIFICATION));
              } else if (response.data.output.billingAddress.networkBandwidthType || response.data.output.billingAddress.fiosQualified) {
                dispatch(updateCurrentView(LQ_VIEWS.ADDRESS_QUALIFICATION));
              } else {
                dispatch(updateCurrentView(LQ_VIEWS.ADDRESS_QUALIFICATION));
                // setCheckAvailBtnTrack('not eligible|AAL', dispatch);
                sendDataVzTagLqa('single');
                sendDataVzTagLqs('single|not eligible');

                // setAddressDetail(dispatch, response.data.output.billingAddress);
                // setQualifiedlist(dispatch, 'not eligible');
              }
              if (loopQualApp.isResponsiveLQ) {
                closeModalWindow(dispatch);
              }
            } else {
              dispatch(updateCurrentView(LQ_VIEWS.ADDRESS_QUALIFICATION));
              if (loopQualApp.isResponsiveLQ) {
                closeModalWindow(dispatch);
              }
            }
          } else if (
            response &&
            response.data &&
            response.data.output &&
            response.data.output.billingAddress &&
            response.data.output.billingAddress.bulkQualAddressType &&
            response.data.output.billingAddress.bulkQualAddressType === 'BillAccount'
          ) {
            setToStorage('LQBulkResponse', JSON.stringify(response));
            singlefromBulkQualUpdate(dispatch, response.data.output.billingAddress, loopQualApp, fiosDetails);
            if (
              response.data.output.billingAddress.networkBandwidthType &&
              !(
                response.data.output.billingAddress.fiosQualified ||
                response.data.output.billingAddress.qualifiedCBand ||
                response.data.output.billingAddress.qualified4GHome ||
                response.data.output.billingAddress.qualified
              )
            ) {
              isFwaExisting(dispatch, true);
              dispatch(updateCurrentView(LQ_VIEWS.ADDRESS_QUALIFICATION));
            } else if (response.data.output.billingAddress.networkBandwidthType || response.data.output.billingAddress.fiosQualified) {
              dispatch(updateCurrentView(LQ_VIEWS.ADDRESS_QUALIFICATION));
            } else {
              dispatch(updateCurrentView(LQ_VIEWS.ADDRESS_QUALIFICATION));
              // setCheckAvailBtnTrack('not eligible|AAL', dispatch);
              sendDataVzTagLqa('single');
              sendDataVzTagLqs('single|not eligible');
              // setAddressDetail(dispatch, response.data.output.billingAddress);
              // setQualifiedlist(dispatch, 'not eligible');
            }
            if (loopQualApp.isResponsiveLQ) {
              closeModalWindow(dispatch);
            }
          } else if (!isRetry && !validError) {
            logMetrics('checkLoggedInBulkFail Retriggering again', JSON.stringify(response), 'Error');
            checkLoggedInBulkQual(
              dispatch,
              loopQualApp,
              fiosDetails,
              addressQualification,
              customerStreetDetails,
              unitLookupDetails,
              moveDetails,
              cookies,
              true,
              bulkQualDetails,
            );
          } else if (response && response.data && response.data.errors) {
            if (validError) {
              logMetrics('checkLoggedInBulkValidDataError', JSON.stringify(response), 'Error');
            } else {
              logMetrics('checkLoggedInBulkDataError', JSON.stringify(response), 'Error');
            }
            dispatch(updateCurrentView(LQ_VIEWS.ADDRESS_QUALIFICATION));
            if (loopQualApp.isResponsiveLQ) {
              closeModalWindow(dispatch);
            }
          } else if (response && response.errors) {
            logMetrics('checkLoggedInBulkResError', JSON.stringify(response), 'Error');
            dispatch(updateCurrentView(LQ_VIEWS.ADDRESS_QUALIFICATION));
            if (loopQualApp.isResponsiveLQ) {
              closeModalWindow(dispatch);
            }
          } else {
            logMetrics('checkLoggedInBulkResponse', JSON.stringify(response), 'Error');
            dispatch(updateCurrentView(LQ_VIEWS.ADDRESS_QUALIFICATION));
            if (loopQualApp.isResponsiveLQ) {
              closeModalWindow(dispatch);
            }
          }
          try {
            const { existingFWA } = response.data.output;
            const { existingMobility } = response.data.output;
            if (!existingFWA) {
              let value = '';
              if (existingMobility) {
                setToStorage('mobilityOnly', 'Y');
                value = 'mobilityOnly=Y;path=/';
                document.cookie = value;
              } else {
                setToStorage('mobilityOnly', 'N');
                value = 'mobilityOnly=N;path=/';
                document.cookie = value;
              }
            }
          } catch (e) {
            console.log(`setting mobilityOnly ${e.message}`);
          }
          if (!isBulkQualOrder) {
            dispatch(hideLoader());
          }
          resolve(response);
          if (isUpdateBulk) {
            dispatch(updateSkeleton(false));
          }
        })
        .catch((error) => {
          if (isUpdateBulk) {
            dispatch(updateSkeleton(false));
          }
          dispatch(hideLoader());
          if (!isRetry) {
            logMetrics('checkLoggedInBulkFail Retriggering catch', error.message, 'Error');
            checkLoggedInBulkQual(
              dispatch,
              loopQualApp,
              fiosDetails,
              addressQualification,
              customerStreetDetails,
              unitLookupDetails,
              moveDetails,
              cookies,
              true,
              bulkQualDetails,
            );
          } else {
            logMetrics('checkLoggedInBulkApiFail', error.message, 'Error');
            dispatch(updateCurrentView(LQ_VIEWS.ADDRESS_QUALIFICATION));
          }
          reject(error);
        });
    });
  } catch (error) {
    if (isUpdateBulk) {
      dispatch(updateSkeleton(false));
    }
    dispatch(hideLoader());
    catchJSException('LOOPQUAL2.0', 'checkLoggedInBulk', error);
    logMetrics('checkLoggedInBulk', error.message, 'Error');
    dispatch({ type: BKActionTypes.PROFILE_BULK_QUALIFICATION_DETAILS, value: {} });
    if (!isRetry) {
      logMetrics('checkLoggedInBulkFail Retriggering again', error.message, 'Error');
      checkLoggedInBulkQual(
        dispatch,
        loopQualApp,
        fiosDetails,
        addressQualification,
        customerStreetDetails,
        unitLookupDetails,
        moveDetails,
        cookies,
        true,
        bulkQualDetails,
      );
    } else {
      logMetrics('checkLoggedInBulkApiFail', error.message, 'Error');
      dispatch(updateCurrentView(LQ_VIEWS.ADDRESS_QUALIFICATION));
    }
  }
};
async function getGeolocationData(data1) {
  const data = data1;
  if (navigator.permissions) {
    const PermissionStatus = await navigator.permissions.query({ name: 'geolocation' });
    if (PermissionStatus.state === 'granted') {
      if (navigator.geolocation) {
        return new Promise((resolve) => {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              data.latitude = position?.coords?.latitude;
              data.longitude = position?.coords?.longitude;
              resolve(data);
            },
            (error) => {
              console.error('Error getting location', error);
              resolve(data);
            },
          );
        });
      }
      return data;
    }
    return data;
  }
  return data;
}
export const checkLoggedInBulkQual_ReFetch = async (
  dispatch,
  loopQualApp,
  fiosDetails,
  addressQualification,
  customerStreetDetails,
  unitLookupDetails,
  moveDetails,
  cookies,
  isRetry = false,
  bulkQualDetails,
) => {
  try {
    const url = apiUrl().fetchAuthDetailsBulk;
    dispatch(showLoader());
    const location =
      window.location.search &&
      (window.location.search.indexOf('testQualification') > -1 ||
        window.location.search.indexOf('nsa=y') > -1 ||
        window.location.search.indexOf('NSA=Y') > -1)
        ? 'Y'
        : null;
    let flowName;
    if (location && location === 'Y') {
      flowName = 'test|';
    }
    const requiredflowType = { flowType: flowName };
    const tgwFlow =
      window.location.search && (window.location.search.indexOf('fcltgw=y') > -1 || window.location.search.indexOf('FCLTGW=Y') > -1) ? 'Y' : null;
    const data = {};
    const EnableWifiBackup = loopQualApp?.runTimeKey?.EnableWifiBackup === 'Y';
    if (wifiBackupQueryParam || EnableWifiBackup) {
      data.wifiBackupRequired = true;
    }
    if (wifiBackupFromFios && isWifiBackupMLP) {
      data.wifiBackupRequired = true;
      data.wifiBackupOnly = true;
      data.isFiosCustomer = true;
    }
    if (params && params?.get('userId')) {
      data.userId = params?.get('userId');
    }
    const isMovers = loopQualApp?.isLoggedIn && loopQualApp?.flowType === Flowtype.MOVERS;
    if (loopQualApp?.isLoggedIn && !isMovers) {
      data.newQualFlow = true;
    }
    if (tgwFlow) {
      data.tanglewoodQualified = 'Y';
    }
    if (loopQualApp.isACPFlow) {
      data.isAcp = true;
    }
    const updatedData = params?.get('loggedInGeo') ? await getGeolocationData(data) : data;
    return new Promise((resolve, reject) => {
      callingApi(url, MethodType.POST, updatedData, 'application/json', requiredflowType)
        .then((response) => {
          console.log(response);
          dispatch(hideLoader());
          if (loopQualApp.isACPFlow && response?.data?.output?.acpInfo) {
            dispatch({ type: ActionTypes.SC_ACP, value: response.data.output.acpInfo?.isAcpExist });
            dispatch({ type: ActionTypes.SC_VFP, value: response.data.output.acpInfo?.isVfExist });
            window.sessionStorage.setItem('isVfExist', response.data.output.acpInfo?.isVfExist);
            window.sessionStorage.setItem('isAcpExist', response.data.output.acpInfo?.isAcpExist);
            if (response?.data?.output.acpInfo?.customerName) {
              const details = {
                firstName: response.data.output.acpInfo.customerName?.firstName,
                lastName: response.data.output.acpInfo.customerName?.lastName,
              };
              dispatch({ type: ActionTypes.SC_DETAILS, value: details });
            }
          }
          resolve(response);
        })
        .catch((error) => {
          dispatch(hideLoader());
          reject(error);
        });
    });
  } catch (error) {
    dispatch(hideLoader());
    catchJSException('LOOPQUAL2.0', 'checkLoggedInBulk', error);
    logMetrics('checkLoggedInBulk', error.message, 'Error');
    dispatch({ type: BKActionTypes.PROFILE_BULK_QUALIFICATION_DETAILS, value: {} });
    if (!isRetry) {
      logMetrics('checkLoggedInBulkFail Retriggering again', error.message, 'Error');
      checkLoggedInBulkQual(
        dispatch,
        loopQualApp,
        fiosDetails,
        addressQualification,
        customerStreetDetails,
        unitLookupDetails,
        moveDetails,
        cookies,
        true,
        bulkQualDetails,
      );
    } else {
      logMetrics('checkLoggedInBulkApiFail', error.message, 'Error');
      dispatch(updateCurrentView(LQ_VIEWS.ADDRESS_QUALIFICATION));
    }
  }
};
export const getBulkService = (qualifiedAddressList) => {
  let qualifiedService = 'multi-lq';
  if (qualifiedAddressList.fiosQualified) {
    qualifiedService = lqServices.fios;
  } else if (qualifiedAddressList.tanglewoodQualified) {
    qualifiedService = lqServices.vshi;
  } else if (qualifiedAddressList.qualified || qualifiedAddressList.qualifiedCBand || qualifiedAddressList?.wifiBackupCbandQualified) {
    // eslint-disable-next-line no-underscore-dangle
    qualifiedService = lqServices.fiveg;
  } else if (qualifiedAddressList.qualified4GHome || qualifiedAddressList?.wifiBackupLteQualified) {
    qualifiedService = lqServices.lte;
  }
  return qualifiedService;
};

export const setPreferredBulkService = (dispatch, service, qualifiedAddressList) => {
  dispatch({ type: BKActionTypes.SET_PREFERRED_BULK_SERVICE, value: service });
  dispatch({ type: BKActionTypes.SET_PREFERRED_BULK_SERVICE_DETAIL, value: qualifiedAddressList });
};

export const setQualifiedlist = (dispatch, value) => {
  dispatch({ type: BKActionTypes.SET_QUALIFIED, value });
};
export const isFwaExisting = (dispatch, value) => {
  dispatch({ type: BKActionTypes.FWA_EXISTING, value });
};
export const setSelectedOne = (dispatch, value) => {
  dispatch({ type: BKActionTypes.SELECTED_LISTED, value });
};
export const resetBulkDetails = () => ({ type: BKActionTypes.RESET_BULK_DETAILS });

export const bulkQualOrderingIntent = (
  dispatch,
  preferredbulkServiceDetail,
  loopQualApp,
  addressQualification,
  unitLookupDetails,
  fiosDetails,
  moveDetails,
  cookies,
  fromBulkOrderNow = false,
  bulkQualDetails,
) => {
  const addrInfo = setAddressDetail(dispatch, preferredbulkServiceDetail);
  const passiveLqsSuppression =
    !loopQualApp.TWS &&
    !loopQualApp.isFccFlow &&
    !loopQualApp.isACPFlow &&
    !preferredbulkServiceDetail.uberPinEligible &&
    loopQualApp.flowType !== Flowtype.storeLocater &&
    loopQualApp.flowType !== Flowtype.MOVERS &&
    loopQualApp.flowType !== Flowtype.retail &&
    !loopQualApp.isComboMFJT &&
    !loopQualApp.isECComboMFJT &&
    !loopQualApp.newConnection;
  if (fromBulkOrderNow) {
    if (!passiveLqsSuppression) {
      sendDataVzTagLqs('multi', preferredbulkServiceDetail);
    }
    if (preferredbulkServiceDetail && preferredbulkServiceDetail.bulkQualAddressType && preferredbulkServiceDetail.bulkQualAddressType !== '') {
      logMetrics('bulkQualAddressType', preferredbulkServiceDetail.bulkQualAddressType, 'Info');
    }
    lqsTagTriggered(dispatch, true);
  }
  if (preferredbulkServiceDetail.fiosQualified || preferredbulkServiceDetail.uberPinEligible) {
    dispatch(hideLoader());
    if (preferredbulkServiceDetail.fiosQualified && fromBulkOrderNow) {
      window.sessionStorage.setItem('VFSelectedService', 'FIOS');
    }
    checkNetworkAvailability(
      addrInfo,
      unitLookupDetails,
      addressQualification,
      loopQualApp,
      dispatch,
      cookies,
      '',
      fiosDetails,
      moveDetails,
      bulkQualDetails,
    );
  } else if (preferredbulkServiceDetail.qualified4GHome || preferredbulkServiceDetail.qualified || preferredbulkServiceDetail.qualifiedCBand) {
    dispatch(hideLoader());
    if (loopQualApp.isACPFlow) {
      if (preferredbulkServiceDetail.qualifiedCBand || preferredbulkServiceDetail.qualified) {
        dispatch(updateCurrentView(LQ_VIEWS.FIVEG_HOME_QUALIFIED_VIEW));
        if (fromBulkOrderNow) {
          window.sessionStorage.setItem('VFSelectedService', '5G');
        } else {
          dispatch({ type: BKActionTypes.SET_SINGLE_BULKQUAL, value: true });
        }
      } else if (preferredbulkServiceDetail.qualified4GHome) {
        dispatch(updateCurrentView(LQ_VIEWS.LTE_HOME_QUALIFIED_VIEW));
        if (fromBulkOrderNow) {
          window.sessionStorage.setItem('VFSelectedService', 'LTE');
        } else {
          dispatch({ type: BKActionTypes.SET_SINGLE_BULKQUAL, value: true });
        }
      }
    } else {
      const lqResult = singlefromBulkQualUpdate(dispatch, preferredbulkServiceDetail, loopQualApp, fiosDetails);
      if ((fromBulkOrderNow || loopQualApp.isResponsiveLQ || loopQualApp.sourceInfo?.toUpperCase() === 'CPC') && !loopQualApp.TWS) {
        dispatch(showLoader());
        //  if (passiveLqsSuppression) {
        dispatch(updateCurrentView(LQ_VIEWS.BLANK_LOADER_VIEW));
        // updatePlanpageViaThrottle(preferredbulkServiceDetail, dispatch, loopQualApp, false, false, false, true);
        // } else {
        setTimeout(() => {
          dispatch(hideLoader());
          // eslint-disable-next-line no-param-reassign
          addressQualification.lqResult = lqResult;
          orderNowFiveGNsa(
            addressQualification,
            loopQualApp,
            dispatch,
            false,
            moveDetails,
            false,
            addrInfo,
            unitLookupDetails,
            fiosDetails,
            false,
            fromBulkOrderNow,
            preferredbulkServiceDetail,
          );
        }, 1000);
        // }
      } else {
        dispatch({ type: BKActionTypes.SET_SINGLE_BULKQUAL, value: true });
      }
    }
  }
};
