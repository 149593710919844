import axios from 'axios';
import { apiUrl, isProd } from '../constants/api-urls';
import { MethodType, Flowtype, LQ_VIEWS, Pages } from '../constants/common';
import {
  genarateToken,
  checkTokenValidity,
  getCookie,
  setToStorage,
  queryStringToJson,
  wifiBackupFromFios,
  isWifiBackupMLP,
  wifiBackupQueryParam,
  isEmailLocus,
} from '../utils/lqServiceUtil';
import { sendDataPassiveLqa, openViewVzTag, sendDataVzTagMessage } from '../utils/unifiedVzTag';
import callingApi from './apiService';
import { transform, FIVG_LQ_RESPONSE, FIOS_LQ_RESPONSE } from '../utils/lq-response-tranformer';
import { AddressQualification as ActionTypes, LoopQualification } from '../constants/actionTypes';
import { logMetrics } from './logService';
import {
  checkNetworkAvailability,
  checkNetworkAvailabilityForCombo,
  updateHelpView,
  updateSeqFwaComboResponse,
} from '../components/address-qualification/actions';
import { openModalWindow } from '../components/common/modal/action';
import { setAddressDetail, setAddressDetailForExternal } from '../components/address-lookup/actions';
import { hideLoader, showLoader } from '../components/common/loader/actions';
import { getFullAddress } from '../components/common/commonUtils';
import { updateCurrentView } from '../components/loop-qual-app/actions';
import { getSeqType } from '../config';
import { onUnitSelection } from '../components/units-lookup/actions';
import { setVendorCookie } from '../components/order-fios/actions';
import { getStoreInfo } from '../utils/vzTagUtil';
/* eslint-disable no-unneeded-ternary */

export const callingLQService = (
  addressInfo,
  unitInfo,
  addressQualification,
  loopQualApp,
  cookies,
  streetInput,
  fiosDetails,
  moveDetails,
  bulkQualDetails,
  sequentialFiosComboOrder = null,
  orderNumber = null,
  comboLocationCode = null,
  shellAcctNoForFios = null,
  emailId = null,
  mtn = null,
  dispatch,
) => {
  let address2Input = unitInfo
    ? unitInfo.customerUnitDetails && unitInfo.customerUnitDetails.ntasSublocation
      ? unitInfo.customerUnitDetails.ntasSublocation
      : unitInfo.unitInput
      ? unitInfo.unitInput
      : ''
    : '';
  if (address2Input) {
    address2Input = ["I don't live in a unit", 'I can’t find my unit'].includes(address2Input)
      ? ''
      : address2Input
          .replace(/[-_/\\^$*+?,.()|[\]{}]/g, ' ')
          .replace(/^\s+|\s+$/g, '')
          .replace(/\s+/g, ' ')
          .replace(/[^a-zA-Z0-9 ]/g, '');
  }
  if (!address2Input && addressInfo) {
    if (addressInfo.parserAddressLine2) {
      address2Input = addressInfo.parserAddressLine2;
    } else if (addressInfo.addressLine2) {
      address2Input = addressInfo.addressLine2;
    }
  }
  return new Promise((resolve, reject) => {
    const lowerEnvHosts = [
      'awssit.ebiz.verizon.com',
      'nte1aws.ebiz.verizon.com',
      'nte3aws.ebiz.verizon.com',
      '98.verizon.com',
      'preprod-east-gz.vpc.verizon.com',
    ];
    const wirelineForLowerEnv = lowerEnvHosts.some((host) => window.location.host.includes(host));
    const params = new URLSearchParams(window.location.search);
    const isComboEmailflow = params.get('isComboEmailflow') ?? null;
    const isComboTestflow = params.get('comboflow') ?? null;

    if (
      ((window.location.search && window.location.search.indexOf('skip5g=y') > -1) || wirelineForLowerEnv) &&
      isComboEmailflow !== 'true' &&
      isComboTestflow !== 'fios'
    ) {
      resolve(
        callFiosLQ(
          addressInfo,
          unitInfo,
          addressQualification,
          loopQualApp,
          cookies,
          address2Input,
          streetInput,
          // fiosDetails,
          sequentialFiosComboOrder,
          orderNumber,
          comboLocationCode,
        ),
      ).catch((error) => {
        reject(error);
        logMetrics('callFiosLQ', error.message, 'Error');
      });
    } else {
      resolve(
        call5GLQ(
          addressInfo,
          unitInfo,
          addressQualification,
          loopQualApp,
          cookies,
          address2Input,
          streetInput,
          fiosDetails,
          moveDetails,
          bulkQualDetails,
          sequentialFiosComboOrder,
          orderNumber,
          comboLocationCode,
          shellAcctNoForFios,
          emailId,
          mtn,
          dispatch,
        ),
      ).catch((error) => {
        reject(error);
        logMetrics('call5GLQ', error.message, 'Error');
      });
    }
  });
};

export const getStreetAddress = (userAddressInput) => {
  const callSoeStreetApi =
    getStoreInfo().loopQualApp?.runTimeKey?.callSoeLocus === 'Y' &&
    (window.location.host.includes('vzwqa1') ||
      window.location.host.includes('vzwqa10') ||
      window.location.host.includes('vzwqa2') ||
      window.location.host.includes('vzwqa20') ||
      isProd());
  const street = userAddressInput
    .replace(/[-_/\\&:';"^$*+?,.()|[\]{}]/g, ' ')
    .replace(/^\s+|\s+$/g, '')
    .replace(/\s+/g, ' ');
  let streetUrl = apiUrl().getStreetAddrLocus;
  if (window.location.host.includes('.verizonwireless.com') && window.location.search && window.location.search.indexOf('masked=y') > -1) {
    streetUrl = 'https://oa-qa.ebiz.verizon.com/locus-address-api/address/typeahead-address?streetterm=';
  }
  let locusApi = `${streetUrl + street.trim()}&countrycode=USA`;
  const userSt = window.sessionStorage.getItem('prefSt');
  if (
    userSt === null &&
    (window.location.host.includes('.ebiz.verizon.com') ||
      window.location.host.includes('98.verizon.com') ||
      window.location.host.includes('ecommstage.verizon.com') ||
      window.location.host.includes('localhost'))
  ) {
    locusApi += '&sortByState=MD';
  }
  if (userSt) {
    locusApi = `${locusApi}&sortByState=${userSt.trim()}`;
  }
  if (
    window.location.host.includes('98.verizon.com') ||
    window.location.host.includes('.ebiz.verizon.com') ||
    window.location.host.includes('ecommstage.verizon.com') ||
    window.location.host.includes('localhost')
  ) {
    locusApi += '&isMaskedAddress=true';
  }
  locusApi += '&size=20';

  let methodType = MethodType.GET;
  let data = '';
  let contentType = '';
  let requiredAuthKey = { apiKey: true };
  if (!street?.trim()) {
    return;
  }
  if (callSoeStreetApi) {
    locusApi = apiUrl().getSOEStreetTerm;
    methodType = MethodType.POST;
    data = {
      streetterm: `${street.trim()}&countrycode=USA&size=20`.replace(/\s/g, '%20'),
    };
    requiredAuthKey = { flowType: '', cancelReqEligible: true };
    contentType = 'application/json';
  }
  return new Promise((resolve, reject) => {
    callingApi(locusApi, methodType, data, contentType, requiredAuthKey)
      .then((response) => {
        const locusSearchResult = response.data.addresses;
        if (locusSearchResult && locusSearchResult.length) {
          resolve(locusSearchResult);
        } else if (!checkTokenValidity()) {
          genarateToken()
            .then((resp) => {
              if (resp.status === 200) {
                invokeNtasStreet(methodType, street, resolve, reject);
              }
            })
            .catch((error) => {
              logMetrics('invokeNtasStreetToken', error.message, 'Error');
            });
        } else {
          invokeNtasStreet(methodType, street, resolve, reject);
        }
      })
      .catch((error) => {
        reject(error);
        if (!(error.message === 'old Request Cancelled')) {
          logMetrics('getStreetAddressLocus', error.message, 'Error');
        }
      });
  });
};
export const invokeNtasStreet = (methodType, street, resolve, reject) => {
  const requiredAuthtoken = { authToken: true };
  let ntasStreet = street;
  let ntasApi = apiUrl().getStreetAddrNTAS + ntasStreet.trim();
  callingApi(ntasApi, 'GET', '', '', requiredAuthtoken)
    .then((ntaResponse) => {
      const ntaSearchResult = ntaResponse.data ? ntaResponse.data.addresses : null;
      if (ntaSearchResult && ntaSearchResult.length) {
        resolve(ntaSearchResult);
      } else {
        const regex = /^(.).*\1$/;
        const streetTrim = ntasStreet.trim();
        if (streetTrim.length > 10 && regex.test(streetTrim)) {
          ntasStreet = streetTrim.slice(0, -1);
          ntasApi = apiUrl().getStreetAddrNTAS + ntasStreet;
          callingApi(ntasApi, 'GET', '', '', requiredAuthtoken)
            .then((ntasResponse) => {
              const ntasSearchResult = ntasResponse.data ? ntasResponse.data.addresses : null;
              resolve(ntasSearchResult || []);
            })
            .catch((error) => {
              reject(error);
              logMetrics('getStreetAddressNtas', error.message, 'Error');
            });
        } else {
          resolve([]);
        }
      }
    })
    .catch((error) => {
      reject(error);
      if (!(error.message === 'old Request Cancelled')) {
        logMetrics('getStreetAddressNtas', error.message, 'Error');
      }
    });
};
export const getUnitForAddress = (streetDetails, unitInput) => {
  const callSoeStreetApi =
    getStoreInfo().loopQualApp?.runTimeKey?.callSoeLocus === 'Y' &&
    (window.location.host.includes('vzwqa1') ||
      window.location.host.includes('vzwqa10') ||
      window.location.host.includes('vzwqa2') ||
      window.location.host.includes('vzwqa20') ||
      isProd());
  const unitTerm = unitInput ? `&unitterm=${unitInput}` : '';
  let unitFetchUrl = apiUrl().getLocusUnits;
  if (window.location.host.includes('.verizonwireless.com') && window.location.search && window.location.search.indexOf('masked=y') > -1) {
    unitFetchUrl = 'https://oa-qa.ebiz.verizon.com/locus-typeahead/address/typeahead-loc-unit';
  }
  if (
    streetDetails &&
    ((streetDetails.locusID && streetDetails.locusID !== 'null') || (streetDetails.ntasAddrID && streetDetails.ntasAddrID !== 'null'))
  ) {
    const locusIDfetch =
      streetDetails.locusID && streetDetails.locusID !== 'null' ? streetDetails.locusID : `${streetDetails.ntasAddrID}&state=${streetDetails.state}`;
    let locusApi2 = `${unitFetchUrl}?locusID=${locusIDfetch}${unitTerm}&size=10`;
    let methodType = MethodType.GET;
    let data = '';
    let contentType = '';
    let requiredAuthKey = { apiKey: true };
    if (callSoeStreetApi) {
      locusApi2 = apiUrl().getSOEUnitTerm;
      methodType = MethodType.POST;
      data = {
        locusID: locusIDfetch,
        unitterm: unitInput,
        size: '10',
      };
      requiredAuthKey = { flowType: '', cancelReqEligible: true };
      contentType = 'application/json';
    }
    return new Promise((resolve, reject) => {
      callingApi(locusApi2, methodType, data, contentType, requiredAuthKey)
        .then((locusResponse) => {
          const result = {
            units: locusResponse.data.data ? locusResponse.data.data : null,
            totalHits: locusResponse.data ? locusResponse.data.totalHits : null,
          };
          if (unitInput === undefined && (result.totalHits === null || result.totalHits === 0)) {
            const addressClassification = streetDetails?.addressClassification;
            // sendDataVzTagErrorMessage(`Base MDU No Units ${addressClassification} ${locusIDfetch}`, 'lq sticky bar');
            logMetrics('Base MDU No Units', `${addressClassification} ${locusIDfetch}`, 'Info');
          }
          resolve(result);
        })
        .catch((error) => {
          reject(error);
          if (!(error.message === 'old Request Cancelled')) {
            logMetrics('getUnitForAddress', error.message, 'Error');
          }
        });
    });
  }
};
export const locusIDfetch = (locusID, dispatch, loopQualApp, fiosDetails, addressQualification, unitLookupDetails, moveDetails, cookies) => {
  const methodType = MethodType.POST;
  let locusId = locusID;
  if (wifiBackupFromFios) {
    const params = new URLSearchParams(window.location.search);
    const ntasId = params.get('ntas_id');
    const state = params.get('state');
    const locusIDParam = params.get('locusID');

    if (ntasId && state) {
      locusId = `${state.toUpperCase()}_${ntasId}_NTAS`;
    } else if (locusIDParam) {
      locusId = locusIDParam;
    }
  }
  const data = {
    includeXrefs: true,
    locusId: [locusId],
  };
  const requiredAuthKey = { apiKey: true };
  return new Promise((resolve, reject) => {
    callingApi(apiUrl().getStreetWithLocusID, methodType, data, '', requiredAuthKey)
      .then((response) => {
        if (response && response.data && response.data.responses && response.data.responses[0] && response.data.responses[0].locationFound) {
          console.log(response.data.responses[0]);
          const addrInfo = setAddressDetailForExternal(dispatch, response.data.responses[0].location);
          if (isEmailLocus) {
            checkNetworkAvailability(
              addrInfo,
              unitLookupDetails,
              addressQualification,
              loopQualApp,
              dispatch,
              cookies,
              '',
              fiosDetails,
              moveDetails,
              null,
              true,
              true,
              false,
              false,
              false,
              true,
            );
          } else {
            checkNetworkAvailability(addrInfo, unitLookupDetails, addressQualification, loopQualApp, dispatch, cookies, '', fiosDetails, moveDetails);
          }
        }
        resolve(response);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};
export const callFiosLQ = (
  addressInfo,
  unitInfo,
  addressQualification,
  loopQualApp,
  cookies,
  address2Input,
  streetInput,
  sequentialFiosComboOrder = null,
  orderNumber = null,
  comboLocationCode = null,
) => {
  let addressID = '';
  if (unitInfo && unitInfo.customerUnitDetails && unitInfo.customerUnitDetails.ntasAddrId) {
    addressID = unitInfo.customerUnitDetails.ntasAddrId.toString();
  } else {
    addressID = addressInfo ? addressInfo.ntasAddrID : '';
  }
  let unitAddressID;
  let unitLocationID;
  if (unitInfo && unitInfo.customerUnitDetails) {
    if (unitInfo.customerUnitDetails.ntasAddrId) {
      unitAddressID = unitInfo.customerUnitDetails.ntasAddrId.toString();
    }
    unitLocationID = unitInfo.customerUnitDetails.location_id;
  }
  const baseAddressIdSubLoc = addressInfo && addressInfo.ntasAddrID && unitLocationID && !unitAddressID ? true : false;
  const payload = sequentialFiosComboOrder
    ? {
        zip: addressInfo ? addressInfo.zip : '',
        skip5GCheck: 'Y',
        deepLooopQual: 'Y',
        city: addressInfo ? addressInfo.muni : '',
        addressline1: addressInfo ? addressInfo.dispstr : streetInput,
        state: addressInfo ? addressInfo.state : '',
        flowtype: 'LQ2.0',
        ntasAddrID: addressID,
        addressline2: address2Input,
        mtn: addressQualification.formattedMtn,
        email: addressQualification.scEmail,
        orderNumber,
        comboLocationCode,
        sequentialFiosComboOrder,
        baseAddressIdSubLoc,
      }
    : {
        zip: addressInfo ? addressInfo.zip : '',
        skip5GCheck: 'Y',
        deepLooopQual: 'Y',
        city: addressInfo ? addressInfo.muni : '',
        addressline1: addressInfo ? addressInfo.dispstr : streetInput,
        state: addressInfo ? addressInfo.state : '',
        flowtype: 'LQ2.0',
        ntasAddrID: addressID,
        addressline2: address2Input,
        mtn: addressQualification.formattedMtn,
        email: addressQualification.scEmail,
        baseAddressIdSubLoc,
      };
  let url = apiUrl().fiosQualification;
  const params = new URLSearchParams(window.location.search);
  const agency = params.get('agency');
  if (agency) {
    url += `?agency=${agency}`;
    sessionStorage.setItem('agency', agency);
  }
  const mockaddress = params.get('mockaddress');
  if (mockaddress) {
    payload.addressline1 = params.get('add1') || payload.addressline1;
    payload.addressline2 = params.get('add2') || payload.addressline2;
    payload.baseAddressIdSubLoc = true;
  }
  return new Promise((resolve, reject) => {
    if (!checkTokenValidity()) {
      genarateToken()
        .then((res) => {
          if (res.status === 200) {
            callingApi(url, MethodType.POST, payload, '', { authToken: true, apiKey: true }).then((response) => {
              const lqResponse = transform(
                response,
                FIOS_LQ_RESPONSE,
                cookies,
                loopQualApp,
                addressInfo,
                streetInput,
                false,
                false,
                null,
                addressQualification,
              );
              resolve(lqResponse);
            });
          }
        })
        .catch((error) => {
          reject(error);
          logMetrics('callFiosLQ', error.message, 'Error');
        });
    } else {
      callingApi(url, MethodType.POST, payload, '', { authToken: true, apiKey: true })
        .then((response) => {
          const lqResponse = transform(
            response,
            FIOS_LQ_RESPONSE,
            cookies,
            loopQualApp,
            addressInfo,
            streetInput,
            false,
            false,
            null,
            addressQualification,
          );
          resolve(lqResponse);
        })
        .catch((error) => {
          reject(error);
          logMetrics('callFiosLQ', error.message, 'Error');
        });
    }
  });
};

export const call5GLQ = (
  addressInfo,
  unitInfo,
  addressQualification,
  loopQualApp,
  cookies,
  address2Input,
  streetInput,
  fiosDetails,
  moveDetails,
  bulkQualDetails,
  sequentialFiosComboOrder = null,
  orderNumber = null,
  comboLocationCode,
  shellAcctNoForFios,
  emailId = null,
  mtn = null,
  dispatch,
) => {
  let data = null;
  let url = apiUrl().fiveGQualification;
  const params = new URLSearchParams(window.location.search);
  const flowOriginatedFrom = window.location.search && window.location.search.indexOf('collateral') > -1 ? 'Collateral' : null;
  let unitAddressID;
  let unitLocationID;
  let bulkQualAddressType;
  let latitudeDetail;
  let longitudeDetail;
  let floorDetail;
  let buildingIdDetail = '';
  let invAdr = false;
  let ntasAddressId;
  let sc_Mtn = addressQualification.formattedMtn ? addressQualification.formattedMtn : addressQualification.scMtn ? addressQualification.scMtn : '';
  if (loopQualApp.isLoggedIn && bulkQualDetails?.bulkProfileDetails?.customerInfo && sc_Mtn === '') {
    sc_Mtn = bulkQualDetails.bulkProfileDetails.customerInfo.loginMtn;
  }
  const vendorOutletIdSession = window.sessionStorage.getItem('vendorOutletId');
  const vendorId = window.sessionStorage.getItem('vendorid');
  const vendorLocationCodeSession = window.sessionStorage.getItem('vendorLocationCode');
  let userSt = window.sessionStorage.getItem('prefSt');
  if (window.location.host.includes('.verizonwireless.com') && userSt === null && params.get('prefSt')) {
    userSt = params.get('prefSt');
  }
  const cVal = params.get('c') ? params.get('c') : '';
  const vendorOutletId = params.get('outletid')
    ? params.get('outletid')
    : fiosDetails && fiosDetails.vendorDetails && fiosDetails.vendorDetails.outletid
    ? fiosDetails.vendorDetails.outletid
    : vendorOutletIdSession || '';
  const vendorLocationCode = params.get('locationCode')
    ? params.get('locationCode')
    : fiosDetails && fiosDetails.vendorDetails && fiosDetails.vendorDetails.locationCode
    ? fiosDetails.vendorDetails.locationCode
    : vendorLocationCodeSession || '';
  const vendor_id =
    cVal || (fiosDetails && fiosDetails.vendorDetails && fiosDetails.vendorDetails.vendorId ? fiosDetails.vendorDetails.vendorId : '') || vendorId;
  let cmp = params.get('cmp') ? params.get('cmp') : params.get('CMP') ? params.get('CMP') : '';
  const isMvPlanChange = loopQualApp.isMvph2;
  let orderNum;
  let comboLocCode;
  const comboResponse = window.sessionStorage.getItem('comboResponseObj');
  let comboResponseObj;
  if (comboResponse && comboResponse !== null) {
    try {
      comboResponseObj = JSON.parse(window?.sessionStorage?.getItem('comboResponseObj'));
    } catch (error) {
      console.log('Error Parsing Json');
    }
  }
  if (params.get('testcombo') === 'y') {
    orderNum = '1234578';
    comboLocCode = 'abcdefgh';
  } else {
    orderNum = orderNumber;
    comboLocCode = comboLocationCode;
  }
  if (cmp === '' && fiosDetails && fiosDetails.vendorDetails && fiosDetails.vendorDetails.cmpid) {
    cmp = fiosDetails.vendorDetails.cmpid;
  }
  if (addressQualification && addressQualification.lqResult && addressQualification.lqResult.mapData) {
    latitudeDetail = addressQualification.lqResult.mapData.latitude;
    longitudeDetail = addressQualification.lqResult.mapData.longitude;
    floorDetail = addressQualification.lqResult.mapData.floorNumber;
    buildingIdDetail = addressQualification.lqResult.mapData.buildingId;
  }
  const preferredbulkServiceDetail = bulkQualDetails && bulkQualDetails.preferredbulkServiceDetail;
  if (loopQualApp.isLoggedIn && bulkQualDetails) {
    if (bulkQualDetails.fiosQualified || (preferredbulkServiceDetail && preferredbulkServiceDetail.fiosQualified)) {
      bulkQualAddressType = 'FIOS';
      if (bulkQualDetails && bulkQualDetails.ntasAddressId) {
        ntasAddressId = bulkQualDetails?.ntasAddressId;
      } else {
        ntasAddressId = preferredbulkServiceDetail?.ntasAddressId;
      }
    } else if (bulkQualDetails?.uberPinEligible || (preferredbulkServiceDetail && preferredbulkServiceDetail.uberPinEligible)) {
      bulkQualAddressType = 'FWA';
    }
  }
  if (unitInfo && unitInfo.customerUnitDetails) {
    if (unitInfo.customerUnitDetails.ntasAddrId) {
      unitAddressID = unitInfo.customerUnitDetails.ntasAddrId.toString();
    }
    unitLocationID = unitInfo.customerUnitDetails.location_id;
  }
  if (window.location.host.includes('awssit.ebiz.verizon.com')) {
    url = 'https://wwwawssit.ebiz.verizon.com/inhome/check5gavailability';
  } else if (window.location.host.includes('nte1aws.ebiz.verizon.com')) {
    url = 'https://wwwnte1aws.ebiz.verizon.com/inhome/check5gavailability';
  } else if (window.location.host.includes('nte3aws.ebiz.verizon.com')) {
    url = 'https://wwwnte3aws.ebiz.verizon.com/inhome/check5gavailability';
  } else if (window.location.host.includes('98.verizon.com')) {
    url = 'https://www98.verizon.com/inhome/check5gavailability';
  } else if (
    loopQualApp.isLoggedIn &&
    loopQualApp.flowType === Flowtype.MOVERS &&
    moveDetails.preferredMVService &&
    moveDetails.preferredMVService.lineLevelInfo &&
    moveDetails.preferredMVService.lineLevelInfo.networkBandwidthType
  ) {
    url = apiUrl().fiveGMVQualification;
  }
  if (
    addressInfo &&
    addressInfo.state &&
    addressInfo.zip &&
    addressInfo.addressLine1 &&
    addressInfo.city &&
    !addressInfo.muni &&
    !addressInfo.dispstr
  ) {
    invAdr = true;
  }
  const baseAddressIdSubLoc = addressInfo && addressInfo.ntasAddrID && unitLocationID && !unitAddressID ? true : false;
  const tgwFlow =
    window.location.search && (window.location.search.indexOf('fcltgw=y') > -1 || window.location.search.indexOf('FCLTGW=Y') > -1) ? 'Y' : null;
  const tgwSeq =
    loopQualApp?.runTimeKey?.tgwSequential === 'Y' && window.location.pathname && window.location.pathname.includes('combo') ? true : false;
  if (addressInfo || streetInput) {
    if (url?.indexOf('movers/check5GMoveAvailability') > -1) {
      data = {
        address1: invAdr ? addressInfo.addressLine1 : addressInfo ? addressInfo.dispstr : streetInput.trim(),
        city: invAdr ? addressInfo.city : addressInfo ? addressInfo.muni : '',
        state: addressInfo ? addressInfo.state : '',
        zipcode: addressInfo ? addressInfo.zip : '',
        ntasAddrID: unitAddressID ? unitAddressID : addressInfo?.ntasAddrID ? addressInfo.ntasAddrID : ntasAddressId,
        locusID: addressInfo ? addressInfo.locusID : '',
        address2: address2Input,
        addressId: unitAddressID,
        location_id: unitLocationID,
        scEmail: addressQualification.scEmail,
        scMtn: sc_Mtn,
        flowtype: 'LQ2.0',
        captchaChangeNeeded: true,
        loopQual: true,
        'g-recaptcha-response': loopQualApp.gCaptchaResponse,
        includeCband: true,
        cBandOnly: false,
        superBowlPromo: false,
        isLoadTest: !!(loopQualApp.runTimeKey && loopQualApp.runTimeKey.EnableNsaLT && loopQualApp.runTimeKey.EnableNsaLT === 'Y'),
        preOrder: true,
        isRevist: addressQualification.lqsTagTriggered,
        referrer: window.document.referrer,
        vendorId: vendor_id,
        cmp: cmp || '',
        outletId: vendorOutletId,
        locationCode: vendorLocationCode,
        movingLineInfo: moveDetails ? moveDetails.preferredMVService : '',
        planChange: isMvPlanChange,
        baseAddressIdSubLoc,
        ...(moveDetails?.moveProfileDetails?.moversPhaseThreeQualFFlag && { cbandPro: 'Y' }),
      };
    } else if (sequentialFiosComboOrder === 'true') {
      data = {
        address1: invAdr ? addressInfo.addressLine1 : addressInfo ? addressInfo.dispstr : streetInput.trim(),
        city: invAdr ? addressInfo.city : addressInfo ? addressInfo.muni : '',
        state: addressInfo ? addressInfo.state : '',
        zipcode: addressInfo ? addressInfo.zip : '',
        ntasAddrID: unitAddressID ? unitAddressID : addressInfo?.ntasAddrID ? addressInfo.ntasAddrID : ntasAddressId,
        locusID: addressInfo ? addressInfo.locusID : '',
        address2: address2Input,
        addressId: unitAddressID,
        location_id: unitLocationID,
        scEmail: addressQualification.scEmail,
        scMtn: sc_Mtn,
        flowtype: 'LQ2.0',
        captchaChangeNeeded: true,
        loopQual: true,
        'g-recaptcha-response': loopQualApp.gCaptchaResponse,
        includeCband: true,
        cBandOnly: false,
        superBowlPromo: false,
        isLoadTest: !!(loopQualApp.runTimeKey && loopQualApp.runTimeKey.EnableNsaLT && loopQualApp.runTimeKey.EnableNsaLT === 'Y'),
        preOrder: true,
        isRevist: addressQualification.lqsTagTriggered,
        referrer: window.document.referrer,
        vendorId: vendor_id,
        cmp: cmp || '',
        outletId: vendorOutletId,
        locationCode: vendorLocationCode,
        cbandPro: 'Y',
        selectedAddresstype: bulkQualAddressType,
        latitude: latitudeDetail,
        longitude: longitudeDetail,
        floorNumber: floorDetail,
        buildingId: buildingIdDetail,
        prefState: userSt || '',
        orderNumber: orderNum,
        comboLocationCode: comboLocCode,
        sequentialFiosComboOrder: params.get('testcombo') === 'y' ? false : sequentialFiosComboOrder,
        shellAcctNoForFios,
        comboMtn: mtn,
        comboEmail: emailId,
        firstName: comboResponseObj?.firstName,
        lastName: comboResponseObj?.lastName,
        baseAddressIdSubLoc,
      };
    } else {
      data = {
        address1: invAdr ? addressInfo.addressLine1 : addressInfo ? addressInfo.dispstr : streetInput.trim(),
        city: invAdr ? addressInfo.city : addressInfo ? addressInfo.muni : '',
        state: addressInfo ? addressInfo.state : '',
        zipcode: addressInfo ? addressInfo.zip : '',
        ntasAddrID: unitAddressID ? unitAddressID : addressInfo?.ntasAddrID ? addressInfo.ntasAddrID : ntasAddressId,
        locusID: addressInfo ? addressInfo.locusID : '',
        address2: address2Input,
        addressId: unitAddressID,
        location_id: unitLocationID,
        scEmail: addressQualification.scEmail,
        scMtn: sc_Mtn,
        flowtype: 'LQ2.0',
        captchaChangeNeeded: true,
        loopQual: true,
        'g-recaptcha-response': loopQualApp.gCaptchaResponse,
        includeCband: true,
        cBandOnly: false,
        superBowlPromo: false,
        isLoadTest: !!(loopQualApp.runTimeKey && loopQualApp.runTimeKey.EnableNsaLT && loopQualApp.runTimeKey.EnableNsaLT === 'Y'),
        preOrder: true,
        isRevist: addressQualification.lqsTagTriggered,
        referrer: window.document.referrer,
        vendorId: vendor_id,
        cmp: cmp || '',
        outletId: vendorOutletId,
        locationCode: vendorLocationCode,
        cbandPro: 'Y',
        selectedAddresstype: bulkQualAddressType,
        latitude: latitudeDetail,
        longitude: longitudeDetail,
        floorNumber: floorDetail,
        buildingId: buildingIdDetail,
        prefState: userSt || '',
        baseAddressIdSubLoc,
      };
    }
  } else {
    data = flowOriginatedFrom
      ? {
          captchaChangeNeeded: true,
          loopQual: true,
          flowOriginatedFrom,
          includeCband: true,
          cBandOnly: false,
        }
      : {
          captchaChangeNeeded: true,
          loopQual: true,
          includeCband: true,
          cBandOnly: false,
        };
  }
  const reqGlobalSession = true;
  const location =
    window.location.search &&
    (window.location.search.indexOf('testQualification') > -1 ||
      window.location.search.indexOf('nsa=y') > -1 ||
      window.location.search.indexOf('NSA=Y') > -1)
      ? 'Y'
      : null;
  let flowName = '';
  const isReinvoke = window.sessionStorage.getItem('isReinvoke');
  const isMoversAPI = url?.indexOf('movers/check5GMoveAvailability') > -1;
  if (loopQualApp.isLoggedIn) {
    flowName = `AAL`;
    if (!isMoversAPI) {
      data.newQualFlow = true;
    }
  } else {
    flowName = `NSE`;
  }
  if (location && location === 'Y') {
    flowName = `test|${flowName}`;
  }
  if (invAdr) {
    flowName = `INVADR|${flowName}`;
  }
  if (isReinvoke === 'Y') {
    flowName = `REINVOKE|${flowName}`;
  }
  if (vendorLocationCode && vendorOutletId) {
    flowName = `Vendor|${vendor_id}|${flowName}`;
    flowName = `OutletID|${vendorOutletId}|${flowName}`;
    setToStorage('UCFlowName', flowName);
  }
  if (streetInput !== '' && addressInfo === null) {
    flowName = `SNGLPARSER|${flowName}`;
    setToStorage('SNGLPARSER', 'Y');
  }
  if (loopQualApp?.isComboMFJT) {
    flowName = `JT_MH|${flowName}`;
  }
  const requiredflowType = { flowType: flowName };
  if (tgwFlow || tgwSeq) {
    data.tanglewoodQualified = 'Y';
  }
  const EnableWifiBackup = loopQualApp?.runTimeKey?.EnableWifiBackup === 'Y';
  if ((wifiBackupQueryParam || EnableWifiBackup) && !isMoversAPI) {
    data.wifiBackupRequired = true;
  }
  if (wifiBackupFromFios && isWifiBackupMLP) {
    data.wifiBackupRequired = true;
    data.wifiBackupOnly = true;
    data.isFiosCustomer = true;
  }
  if (params && params?.get('userId')) {
    data.userId = params?.get('userId');
  }
  const mockaddress = params.get('mockaddress');
  if (mockaddress) {
    data.address1 = params.get('add1') || data.address1;
    data.address2 = params.get('add2') || data.address2;
    data.baseAddressIdSubLoc = true;
  }

  // if (checkIsHomeInternet() && !(loopQualApp.isLoggedIn || loopQualApp.isLoggedInUserInfo) && loopQualApp?.runTimeKey?.EnableSOThrottle === 'Y') {
  //   data.invokeSOThrottle = true;
  // }
  return new Promise((resolve, reject) => {
    callingApi(url, MethodType.POST, data, '', requiredflowType, reqGlobalSession)
      .then((response) => {
        window.sessionStorage.setItem('check5GTriggered', true);
        const lqResponse = transform(
          response.data,
          FIVG_LQ_RESPONSE,
          cookies,
          loopQualApp,
          addressInfo,
          streetInput,
          invAdr,
          false,
          null,
          addressQualification,
        );
        if (isReinvoke === 'Y') {
          setToStorage('isReinvoke', '');
        }
        if (vendor_id && vendorOutletId) {
          logMetrics('VendorFlow', 'VendorFlow-NSA', 'Info');
        }
        setToStorage('lqSrc', 'lqAvail');
        const baseNtas = !!window.sessionStorage.getItem('BaseNtas');
        if (loopQualApp?.isSeqFwaEmailflow === true) {
          if (comboResponseObj?.acctRegistrationStatus === 'true' || comboResponseObj?.mtnActivationStatus === 'true') {
            openModalWindow('LQAPP', dispatch);
            dispatch(updateCurrentView(LQ_VIEWS.SEQUENTIAL_FWA_LOGIN));
          }
        }
        if (loopQualApp?.isComboEmailflow === true) {
          const visitid = lqResponse.visit_id;
          const visitorid = lqResponse.visitor_id;
          setVendorCookie(visitid, visitorid, dispatch);
          if (comboResponseObj?.acctRegistrationStatus === 'true' || comboResponseObj?.mtnActivationStatus === 'true') {
            openModalWindow('LQAPP', dispatch);
            dispatch(updateCurrentView(LQ_VIEWS.COMBO_EMAIL_SIGNIN_VIEW));
          } else {
            const redirectUrl = window.location.host.includes('.verizonwireless.com')
              ? `https://wwwnte1aws.ebiz.verizon.com${Pages.InHomeIntermediary}?visitor_id=${lqResponse.visitor_id}&visit_id=${lqResponse.visit_id}&emailId=${emailId}&languageIndicator=${comboResponseObj?.languageIndicator}&isComboEmailflow=${comboResponseObj?.isComboEmailflow}&firstName=${comboResponseObj?.firstName}`
              : Pages.InHomeIntermediary;
            window.location.href = redirectUrl;
            return;
          }
        }
        if (
          !baseNtas &&
          !loopQualApp.isLoggedIn &&
          !loopQualApp.isLoggedInUserInfo &&
          addressInfo &&
          addressInfo.ntasAddrID &&
          unitLocationID &&
          !unitAddressID
        ) {
          logMetrics('BaseNtas', 'UnitNtasMissing', 'Info');
          setToStorage('BaseNtas', 'Y');
        }
        resolve(lqResponse);
      })
      .catch((error) => {
        reject(error);
        logMetrics('fiveGQualification', error.message, 'Error');
      });
  });
};
export const fetchReCaptchaDetailsForNSA = () => {
  const url = apiUrl().recaptchaDetails;
  const location =
    window.location.search &&
    (window.location.search.indexOf('testQualification') > -1 ||
      window.location.search.indexOf('nsa=y') > -1 ||
      window.location.search.indexOf('NSA=Y') > -1)
      ? 'Y'
      : null;
  let flowName;
  if (location && location === 'Y') {
    flowName = 'test|';
  }
  const requiredflowType = { flowType: flowName };
  return new Promise((resolve, reject) => {
    callingApi(url, MethodType.POST, '{}', 'application/json', requiredflowType)
      .then((response) => {
        const recaptchaDetails = response.data ? response.data : {};
        resolve(recaptchaDetails);
      })
      .catch((error) => {
        reject(error);
        logMetrics('fetchReCaptchaDetailsForNSA', error.message, 'Error');
      });
  });
};

/** Set AEM flag for performance */
const setAEMKeyToStorage = (res) => {
  if (res?.data?.EnableParallelAPICalls) {
    setToStorage('EnableParallelAPICalls', res?.data?.EnableParallelAPICalls);
  }
  if (res?.data?.EnableNewBPPParallelAPICalls) {
    setToStorage('EnableNewBPPParallelAPICalls', res?.data?.EnableNewBPPParallelAPICalls);
  }
};
export const getRuntimekey = async (dispatch) => {
  try {
    if (
      window.location.host.includes('.ebiz.verizon.com') ||
      (window.location.search && window.location.search.toLowerCase().indexOf('lqTestQualification=Y') > -1) ||
      window.location.host.includes('98.verizon.com') ||
      window.location.host.includes('qa') ||
      window.location.host.includes('nssit') ||
      window.location.host.includes('ecommstage.verizon.com') ||
      window.location.host.includes('localhost')
    ) {
      const res = runtimeKeys();
      dispatch({ type: LoopQualification.SET_RUNTIME_KEY, value: res && res.data ? res.data : '' });
      setToStorage('runtimeKeysLQ', response && response.data ? JSON.stringify(response.data) : '');
      setAEMKeyToStorage(response);
      return res;
    }
    let url = apiUrl().runtimeKey;
    const { host } = window.location;
    if (host.indexOf('sit') > -1 || host.indexOf('nte3') > -1 || host.indexOf('nte1') > -1) {
      url = '/inhome/getruntimekeys';
    }
    if (host.indexOf('preprod') > -1 || host.indexOf('98') > -1 || window.location.href.indexOf('http://localhost') > -1) {
      url = 'https://sales-bau-sit2-publisher.ebiz.verizon.com/personal/services/getaccordionmapping.runtimekeys.json';
    }
    const response = await axios
      .get(url, {
        responseType: 'json',
        cache: false,
        headers: {
          'Content-Type': 'application/json',
          Pragma: 'no-cache',
          'Cache-Control': 'no-store',
        },
        timeout: 90000,
      })
      .catch((error) => {
        logMetrics('getRuntimekey', error.message, 'Error');
        if (
          window.location.host.includes('qa') ||
          window.location.host.includes('nssit') ||
          window.location.host.includes('ecommstage.verizon.com') ||
          window.location.host.includes('localhost') ||
          window.location.host.includes('.ebiz.verizon.com')
        ) {
          const res = runtimeKeys();
          dispatch({ type: LoopQualification.SET_RUNTIME_KEY, value: res && res.data ? res.data : '' });
          setToStorage('runtimeKeysLQ', response && response.data ? JSON.stringify(response.data) : '');
          setAEMKeyToStorage(res);
          return res;
        }
        return null;
      });
    dispatch({ type: LoopQualification.SET_RUNTIME_KEY, value: response && response.data ? response.data : '' });
    setToStorage('runtimeKeysLQ', response && response.data ? JSON.stringify(response.data) : '');
    setAEMKeyToStorage(response);
    return response;
  } catch (e) {
    logMetrics('getRuntimekey', e.message, 'Error');
    return null;
  }
};

const runtimeKeys = () => {
  const res = {
    data: {
      EnableCaptcha: 'N',
      EnableNsaLT: 'N',
      EnableHeaderFooter: 'N',
      EnableMoverPh2: 'Y',
      EnableMoverPh2Titan: 'N',
      DisableMoversPh2: 'N',
      allowTGWOrder: 'N',
      tgwSequential: 'Y',
      isVFNYExistEnable: 'Y',
      EnableProspectSessionTimeout: 'Y',
      EnableLoggedInSessionTimeout: 'Y',
      EnablePrefetchFios: 'Y',
      EnablePrefetchFiosExtended: 'Y',
      PrefetchHome: 'Y',
      callSoeLocus: 'Y',
      EnableSOThrottle: 'Y',
      EnableWifiBackup: 'Y',
      AllowAccountMember: 'Y',
      isVFNYDiscountEnable: 'Y',
    },
  };
  return res;
};
export const getCookieVisitIdAndVisitorIdNoParam = async () => {
  const generateCookieUrl = apiUrl().getServerVisitIdAndVisitorId;
  const response = await axios
    .get(generateCookieUrl, {
      responseType: 'json',
      withCredentials: true,
      cache: false,
      headers: {
        'Content-Type': 'application/json',
        Pragma: 'no-cache',
        'Cache-Control': 'no-store',
      },
      timeout: 90000,
    })
    .catch((error) => logMetrics('getCookieVisitIdAndVisitorIdNoParam', error.message, 'Error'));
  return response;
};
export const fetchAuthDetails = (cookies, loopQualApp, addressInfo, apiTriggerWithoutViewUpdate = false) => {
  try {
    let url = apiUrl().fetchAuthDetails;
    if (window.location.host.includes('awssit.ebiz.verizon.com')) {
      url = 'https://wwwawssit.ebiz.verizon.com/inhome/check5gavailabilityAuth';
    } else if (window.location.host.includes('nte1aws.ebiz.verizon.com')) {
      url = 'https://wwwnte1aws.ebiz.verizon.com/inhome/check5gavailabilityAuth';
    } else if (window.location.host.includes('nte3aws.ebiz.verizon.com')) {
      url = 'https://wwwnte3aws.ebiz.verizon.com/inhome/check5gavailabilityAuth';
    } else if (window.location.host.includes('98.verizon.com')) {
      url = 'https://www98.verizon.com/inhome/check5gavailabilityAuth';
    }
    const params = new URLSearchParams(window.location.search);
    const cVal = params.get('c') ? params.get('c') : '';
    const outletId = params.get('outletid') ? params.get('outletid') : '';
    const locationCode = params.get('locationCode') ? params.get('locationCode') : '';
    const data = {
      captchaChangeNeeded: true,
      vendorId: cVal,
      outletId,
      locationCode,
      includeCband: true,
      cBandOnly: false,
      preOrder: true,
      cbandPro: 'Y',
    };
    if (loopQualApp.isACPFlow) {
      data.isAcp = true;
    }
    const location =
      window.location.search &&
      (window.location.search.indexOf('testQualification') > -1 ||
        window.location.search.indexOf('nsa=y') > -1 ||
        window.location.search.indexOf('NSA=Y') > -1)
        ? 'Y'
        : null;
    let flowName;
    if (location && location === 'Y') {
      flowName = 'test|';
    }
    const requiredflowType = { flowType: flowName };
    return new Promise((resolve, reject) => {
      callingApi(url, MethodType.POST, data, '', requiredflowType)
        .then((response) => {
          window.sessionStorage.setItem('fetchAuthDetails', true);
          if (loopQualApp.isACPFlow && response?.data?.output?.acpInfo) {
            window.sessionStorage.setItem('isVfExist', response.data?.output?.acpInfo.isVfExist);
            window.sessionStorage.setItem('isAcpExist', response.data?.output?.acpInfo.isAcpExist);
            window.sessionStorage.setItem('isSwitcherPromoEligible', response.data?.output?.acpInfo.SwitcherPromoEligible);
          }
          setToStorage('lqSrc', 'lqinit');
          if (!apiTriggerWithoutViewUpdate) {
            const lqResponse = transform(response.data, FIVG_LQ_RESPONSE, cookies, loopQualApp, addressInfo, '');
            resolve(lqResponse);
          } else {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
          logMetrics('fetchAuthDetails', error.message, 'Error');
        });
    });
  } catch (ex) {
    logMetrics('fetchAuthDetails', ex.message, 'Error');
  }
};

export const fetchAuthDetailsDAW = (newConnection, isLoggedInUserInfo, isLoggedIn, loopQualApp) => {
  let redirectUrl = ''; // new connection flow redirection with coupon - DAW
  if (isLoggedInUserInfo) {
    redirectUrl = `https://${window.location.host}/inhome/change/signin?flowtype=af&PN=${newConnection}&lq2=y`;
    logMetrics('DAW - Coupon', 'Fios redirection', 'Info');
    window.location.href = redirectUrl;
  } else if (isLoggedIn) {
    return new Promise((resolve) => {
      fetchAuthDetails('', loopQualApp, '', true)
        .then((response) => {
          if (response) {
            if (response?.data?.output?.oneVzIdCustomer && (response?.data?.output?.existingFios || !response?.data?.output?.existingFWA)) {
              redirectUrl = `https://${window.location.host}/consumer/myverizon/router?target=https:%2F%2Fwww.verizon.com%2Finhome%2Fchange%2Fsignin%253fflowtype%253daf%2526source%253dlq2%2526customerintent%253dACCORDION%2526subflowtype%253daccordion%2526PN%3D${newConnection}`;
              logMetrics('DAW - fetchAuthDetails - Coupon', 'Fios redirection with onevzid', 'Info');
            } else {
              redirectUrl = `https://${window.location.host}/digital/nsa/secure/ui/vzup/#/fwadealsatwork?couponCode=${newConnection}`;
              logMetrics('DAW - fetchAuthDetails - Coupon', 'FWA redirection', 'Info');
            }
          }
          window.location.href = redirectUrl;
          resolve(response);
        })
        .catch((error) => {
          redirectUrl = `https://${window.location.host}/digital/nsa/secure/ui/vzup/#/fwadealsatwork?couponCode=${newConnection}`;
          window.location.href = redirectUrl;
          logMetrics('fetchAuthDetailsApi - Coupon', error.message, 'Error');
        });
    });
  }
};
export const comboLqint = (dispatch, loopQualApp, fiosDetails, addressQualification, unitLookupDetails, moveDetails) => {
  try {
    // dispatch(showLoader());
    let queryParams = '';
    if (window.location.search) {
      queryParams = window.location.search;
    }
    let url;
    const params = new URLSearchParams(window.location.search);
    const aalSeqType = getSeqType() === 'EC_MOC_FWA';
    const fwaEmailSeqType = getSeqType() === 'MEC_FWA_NSE';
    const seqFwaEmailFlow = params.get('isFWAEmailOrderingFlow') ?? null;
    if (window.location.host.includes('awssit.ebiz.verizon.com')) {
      url = 'https://wwwawssit.ebiz.verizon.com/inhome/comboInit';
    } else if (window.location.host.includes('nte1aws.ebiz.verizon.com')) {
      url = 'https://wwwnte1aws.ebiz.verizon.com/inhome/comboInit';
    } else if (window.location.host.includes('nte3aws.ebiz.verizon.com')) {
      url = 'https://wwwnte3aws.ebiz.verizon.com/inhome/comboInit';
    } else if (window.location.host.includes('98.verizon.com')) {
      url = 'https://www98.verizon.com/inhome/comboInit';
    } else if (window.location.host.includes('localhost') || (window.location.search && window.location.search.indexOf('testbau=y') > -1)) {
      url = 'https://www.verizon.com/vfw/v1/comboInit';
    } else if (params.get('isComboEmailflow') === 'true' || (seqFwaEmailFlow && fwaEmailSeqType)) {
      url = apiUrl().fetchEmailComboInit;
    } else {
      url = apiUrl().fetchComboInit;
    }
    const digiSession = params.get('dis');
    let data = queryParams ? queryStringToJson(queryParams.replace('?', '')) : { orderId: '123', cartId: '123', locationCode: '123' };
    if (params.get('isComboEmailflow') === 'true' || seqFwaEmailFlow) {
      const { shellAccountNumber, ...rest } = data;
      data = {
        ...rest,
        shellAccountNo: shellAccountNumber,
      };
    }
    const requiredflowType = { flowType: '' };
    if (aalSeqType) {
      data.seqType = 'EC';
    }
    if (params.get('comboflow') === 'fios') {
      data.flowType = 'fios';
      if (!loopQualApp.isLoggedIn) {
        data.seqType = 'NC';
      }
    }

    return new Promise((resolve, reject) => {
      callingApi(url, MethodType.POST, data, '', requiredflowType, digiSession)
        .then((response) => {
          if (response && response.data.customerShippingAddr) {
            // sendDataVzTagLqa('single');
            // lqsTagTriggered(dispatch, true);
            let sequentialFiosComboOrder;
            let orderNumber;
            let comboLocationCode;
            let shellAcctNoForFios;
            let emailId;
            let mtn;
            const comboResponseObj = {
              languageIndicator: '',
              isComboEmailflow: '',
              acctRegistrationStatus: '',
              mtnActivationStatus: '',
              firstName: '',
              lastName: '',
            };
            const addrInfo = setAddressDetail(dispatch, response.data.customerShippingAddr);
            const seqFwaComboObj = {
              emailId: '',
              languageIndicator: '',
              firstName: '',
              accountNumber: '',
              addrInfo: {},
            };
            if (params.get('testcombo') === 'y') {
              sequentialFiosComboOrder = true;
              if (!params.has('dis')) {
                orderNumber = '1234578';
                comboLocationCode = 'abcdefgh';
              }
            } else if (response?.data?.isSequentialFiosComboOrder === 'true') {
              sequentialFiosComboOrder = response?.data?.isSequentialFiosComboOrder || null;
              orderNumber = response?.data?.comboInternalOrderNumber || null;
              comboLocationCode = response?.data?.completedOrderLocCode || null;
              shellAcctNoForFios = response?.data?.shellAcctNoForFios || null;
              emailId = response?.data?.emailId || null;
              mtn = response?.data?.mtn || null;
              comboResponseObj.languageIndicator = response?.data?.languageIndicator || null;
              comboResponseObj.isComboEmailflow = response?.data?.isComboEmailflow || null;
              comboResponseObj.acctRegistrationStatus = response?.data?.acctRegistrationStatus || null;
              comboResponseObj.mtnActivationStatus = response?.data?.mtnActivationStatus || null;
              comboResponseObj.firstName = response?.data?.firstName || null;
              comboResponseObj.lastName = response?.data?.lastName || null;
              window.sessionStorage.setItem('comboResponseObj', JSON.stringify(comboResponseObj));
            } else if (response?.data?.isFWAEmailOrderingFlow === 'true') {
              comboResponseObj.acctRegistrationStatus = response?.data?.acctRegistrationStatus || null;
              comboResponseObj.mtnActivationStatus = response?.data?.mtnActivationStatus || null;
              comboResponseObj.firstName = response?.data?.firstName || null;
              comboResponseObj.languageIndicator = response?.data?.languageIndicator || null;
              seqFwaComboObj.emailId = response?.data?.emailId || null;
              seqFwaComboObj.accountNumber = response?.data?.shellAcctNoForFios || null;
              seqFwaComboObj.firstName = response?.data?.firstName || null;
              seqFwaComboObj.languageIndicator = response?.data?.languageIndicator || null;
              seqFwaComboObj.addrInfo = addrInfo;
              dispatch(updateSeqFwaComboResponse(seqFwaComboObj));
              window.sessionStorage.setItem('comboResponseObj', JSON.stringify(comboResponseObj));
            }
            addrInfo.ntasAddrID = response?.data?.addressId ? response?.data?.addressId : null;
            // addrInfo.ntasAddrID = '13364464';
            if (
              seqFwaEmailFlow &&
              fwaEmailSeqType &&
              response?.data?.mtnActivationStatus !== 'true' &&
              response?.data?.acctRegistrationStatus !== 'true'
            ) {
              dispatch(hideLoader());
              openModalWindow('LQAPP', dispatch);
              dispatch(updateCurrentView(LQ_VIEWS.EMAIL_AUTHENTICATION));
            } else {
              setTimeout(() => {
                dispatch(hideLoader());
                // add here
                checkNetworkAvailabilityForCombo(
                  addrInfo,
                  unitLookupDetails,
                  addressQualification,
                  loopQualApp,
                  dispatch,
                  null,
                  '',
                  fiosDetails,
                  moveDetails,
                  sequentialFiosComboOrder,
                  orderNumber,
                  comboLocationCode,
                  shellAcctNoForFios,
                  emailId,
                  mtn,
                );
              }, 2000);
            }
          } else {
            dispatch(hideLoader());
            openModalWindow('LQAPP', dispatch);
            updateCurrentView(LQ_VIEWS.ADDRESS_QUALIFICATION);
          }
        })
        .catch((error) => {
          dispatch(hideLoader());
          logMetrics('comboLqint', error.message, 'Error');
          openModalWindow('LQAPP', dispatch);
          reject(error);
          updateCurrentView(LQ_VIEWS.ADDRESS_QUALIFICATION);
        });
    });
  } catch (ex) {
    dispatch(hideLoader());
    logMetrics('comboLqint', ex.message, 'Error');
    openModalWindow('LQAPP', dispatch);
    updateCurrentView(LQ_VIEWS.ADDRESS_QUALIFICATION);
  }
};

export const checkPdmNse = (type, lqResultAddrInfo = null) => {
  try {
    let data = null;
    if (type === 'upsert' && lqResultAddrInfo) {
      data = {
        source: 'upsertSoi',
        topic: 'soi_fwa_digital_insights',
        serviceRequest: {
          globalId: getCookie('GLOBALID'),
          insightCategory: 'loopqual',
          insights: [
            {
              insightName: 'VZDev',
              insightValue: { CTIME: new Date() },
            },
            {
              insightName: 'VzLQPro',
              insightValue: {
                FMSL: '40',
                LQ: 'LTE',
                FMS: 'LTE',
                playSessionId: '',
                purchasePath: '5G_NSE',
                orderSubmitted: 'false',
                FiOSUpSp: '1024',
                FiOSSp: '1024',
                Tarcode: '1107',
                DMA: '506',
                address1: lqResultAddrInfo ? lqResultAddrInfo.addressLine1 : '',
                city: lqResultAddrInfo ? lqResultAddrInfo.city : '',
                zipcode: lqResultAddrInfo ? lqResultAddrInfo.zip : '',
                state: lqResultAddrInfo ? lqResultAddrInfo.state : '',
              },
            },
          ],
        },
      };
    } else {
      data = {
        source: 'getSoi',
        serviceRequest: {
          globalId: getCookie('GLOBALID'),
          insightCategory: ['loopqual'],
          insightName: [],
          additionalParams: {},
        },
      };
    }
    const url = apiUrl().checkPDM;
    const location =
      window.location.search &&
      (window.location.search.indexOf('testQualification') > -1 ||
        window.location.search.indexOf('nsa=y') > -1 ||
        window.location.search.indexOf('NSA=Y') > -1)
        ? 'Y'
        : null;
    let flowName;
    if (location && location === 'Y') {
      flowName = 'test|';
    }
    const requiredflowType = { flowType: flowName };
    return new Promise((resolve, reject) => {
      callingApi(url, MethodType.POST, data, '', requiredflowType)
        .then((response) => {
          const pdmResponse = response.data ? response.data : {};
          resolve(pdmResponse);
        })
        .catch((error) => {
          reject(error);
          logMetrics('checkPdmNse', error.message, 'Error');
        });
    });
  } catch (ex) {
    logMetrics('checkPdmNse', ex.message, 'Error');
  }
};

export const updatePdm = (responselist) => {
  try {
    const lqResultAddrInfo = responselist.addrInfo;
    let qualifiedService = '';
    if (responselist.fiosQualified) {
      qualifiedService = 'FIOS';
    } else if (responselist.fiveGQualified || responselist.qualifiedCBand) {
      qualifiedService = '5G';
    } else if (responselist.lteQualified) {
      qualifiedService = 'LTE';
    }
    const insightsDatas = [
      {
        insightName: 'vzdev',
        insightValue: { CTIME: new Date() },
      },
      {
        insightName: 'vzlqpro',
        insightValue: {
          FMSL: '40',
          LQ: qualifiedService,
          FMS: qualifiedService,
          playSessionId: '',
          purchasePath: '5G_NSE',
          orderSubmitted: 'false',
          FiOSUpSp: '1024',
          FiOSSp: '1024',
          Tarcode: '',
          DMA: '',
          address1: lqResultAddrInfo ? lqResultAddrInfo.addressLine1 : '',
          city: lqResultAddrInfo ? lqResultAddrInfo.city : '',
          zipcode: lqResultAddrInfo ? lqResultAddrInfo.zip : '',
          state: lqResultAddrInfo ? lqResultAddrInfo.state : '',
        },
      },
    ];
    sessionStorage.setItem('upsertSOIInsights', JSON.stringify(insightsDatas));
    const data = {
      source: 'upsertSoi',
      topic: 'soi_fwa_digital_insights',
      serviceRequest: {
        globalId: getCookie('GLOBALID'),
        insightCategory: 'loopqual',
        insights: insightsDatas,
      },
    };

    const url = apiUrl().checkPDM;
    const location =
      window.location.search &&
      (window.location.search.indexOf('testQualification') > -1 ||
        window.location.search.indexOf('nsa=y') > -1 ||
        window.location.search.indexOf('NSA=Y') > -1)
        ? 'Y'
        : null;
    let flowName;
    if (location && location === 'Y') {
      flowName = 'test|';
    }
    const requiredflowType = { flowType: flowName };
    return new Promise((resolve, reject) => {
      callingApi(url, MethodType.POST, data, '', requiredflowType)
        .then((response) => {
          const pdmResponse = response.data ? response.data : {};
          resolve(pdmResponse);
        })
        .catch((error) => {
          reject(error);
          logMetrics('updatePdm', error.message, 'Error');
        });
    });
  } catch (ex) {
    logMetrics('updatePdm', ex.message, 'Error');
  }
};

export const getPdm = (dispatch) => {
  try {
    const data = {
      source: 'getSoi',
      serviceRequest: {
        globalId: getCookie('GLOBALID'),
        insightCategory: ['vhiordering'],
        insightName: [],
        additionalParams: {},
      },
    };

    const url = apiUrl().checkPDM;
    const location =
      window.location.search &&
      (window.location.search.indexOf('testQualification') > -1 ||
        window.location.search.indexOf('nsa=y') > -1 ||
        window.location.search.indexOf('NSA=Y') > -1)
        ? 'Y'
        : null;
    let flowName;
    if (location && location === 'Y') {
      flowName = 'test|';
    }
    const requiredflowType = { flowType: flowName };
    return new Promise((resolve, reject) => {
      callingApi(url, MethodType.POST, data, '', requiredflowType)
        .then((response) => {
          if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
            const categoryInsights =
              response.data.response && response.data.response.data && response.data.response.data.categoryInsights
                ? response.data.response.data.categoryInsights
                : '';
            if (categoryInsights && categoryInsights.length > 0 && categoryInsights[0].insights) {
              const vzCart = categoryInsights && categoryInsights[0].insights.filter((p) => p.name === 'vzcart');
              const smartCart = vzCart && vzCart[0] && vzCart[0].value && vzCart[0].value.smartCart ? vzCart[0].value.smartCart : 'N';
              const orderSubmitted = vzCart && vzCart[0] && vzCart[0].value && vzCart[0].value.orderSubmitted ? vzCart[0].value.orderSubmitted : 'N';
              const sessionIdFromPdm = vzCart && vzCart[0] && vzCart[0].value && vzCart[0].value.sessionId;
              if (sessionIdFromPdm) {
                window.sessionStorage.setItem('sessionIdFromPdm', sessionIdFromPdm);
              }
              if (smartCart && orderSubmitted === 'N') {
                dispatch({ type: ActionTypes.SMARTCART_PDM, value: smartCart === 'Y' ? 'Y' : 'N' });
                if (smartCart === 'Y') {
                  window.sessionStorage.setItem('GetPdc_Smartcart', 'Y');
                  logMetrics('getPdm', 'smartCart is Y', 'Info');
                }
              } else {
                dispatch({ type: ActionTypes.SMARTCART_PDM, value: 'N' });
              }
            }
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
          logMetrics('getPdm', error.message, 'Error');
        });
    });
  } catch (ex) {
    logMetrics('getPdm', ex.message, 'Error');
  }
};

export const onHelpKeyDown = (e, dispatch) => {
  if (e.charCode === 13) {
    onHelpClick(e, dispatch);
  }
};
export const onHelpClick = (e, dispatch) => {
  e.preventDefault();
  logMetrics('onHelpClick', 'ONHELPCLICKED', 'Info');
  openModalWindow('LQAPP', dispatch);
  openViewVzTag('lq help');
  updateHelpView(dispatch, true);
};
export const fiosProfile = async (dispatch, loopQualApp, notAcp, isRetry = false, isLoggedInTWSTK) => {
  try {
    const url = apiUrl().fetchFiosProfile;
    const req = {
      caller: 'ACCOUNT_ACCESS',
      queryString: '?flowtype=af&source=urc&customerintent=ACCORDION&subflowtype=accordion&FirstVisit=&FlowRoute=',
    };
    const details = {
      firstName: '',
      middleName: '',
      lastName: '',
      DateOfBirth: '',
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      city: '',
      state: '',
      zipCode: '',
    };
    dispatch(showLoader());
    return new Promise((resolve, reject) => {
      callingApi(url, MethodType.POST, req, '', { authToken: true })
        .then((response) => {
          dispatch(hideLoader());
          if (response?.data?.data?.accountAddress === null && response?.data?.data?.fiosQualified === false && !isRetry) {
            fiosProfile(dispatch, loopQualApp, notAcp, true);
          } else {
            if (loopQualApp.isLoggedInUserInfo && loopQualApp.isACPFlow) {
              sendDataPassiveLqa('lq-address', 'acp');
            }
            details.firstName = response?.data?.data?.firstName;
            details.middleName = response?.data?.data?.middleName;
            details.lastName = response?.data?.data?.lastName;
            details.DateOfBirth = response?.data?.data?.DateOfBirth;
            details.addressLine1 = response?.data?.data?.accountAddress?.addressLine1;
            details.addressLine2 = response?.data?.data?.accountAddress?.addressLine2;
            details.addressLine3 = response?.data?.data?.accountAddress?.addressLine3;
            details.city = response?.data?.data?.accountAddress?.city;
            details.state = response?.data?.data?.accountAddress?.state;
            details.zipCode = response?.data?.data?.accountAddress?.zip;
            dispatch({ type: ActionTypes.SC_DETAILS, value: details });
            if (!notAcp) {
              if (loopQualApp.isACPFlow) {
                const isAcpPresent = response?.data?.data?.emeriosEnrollmentNumber !== null;
                dispatch({ type: ActionTypes.SC_ACP, value: isAcpPresent });
                dispatch({ type: ActionTypes.SC_VFP, value: isAcpPresent });
              }
            }
            const lqResObj = {};
            const lqAddress = getFullAddress(details);
            const addrInfo = {
              addressLine1: details.addressLine1,
              addressLine2: details.addressLine2,
              city: details.city,
              state: details.state,
              lqAddress,
              zip: details.zipCode,
              muni: details.city,
              dispstr: lqAddress,
            };
            lqResObj.fiosQualified = response?.data?.data?.fiosQualified === true;
            lqResObj.lqAddress = lqAddress;
            lqResObj.addrInfo = addrInfo;
            dispatch({ type: ActionTypes.QUALIFICATION_DETAILS, value: lqResObj });
            if (!notAcp && !isLoggedInTWSTK) {
              if (response?.data?.data?.businessLine === 'Fiber') {
                dispatch(updateCurrentView(LQ_VIEWS.FIOS_QUALIFIED_VIEW));
              } else {
                dispatch(updateCurrentView(LQ_VIEWS.UN_QUALIFIED_VIEW));
              }
            } else if (isLoggedInTWSTK) {
              sessionStorage.setItem('existingFiosLoggedIn', 'true');
            }
          }
          if (notAcp && !isLoggedInTWSTK) {
            sendDataVzTagMessage('', 'lq sticky bar - address displayed');
            dispatch(updateCurrentView(LQ_VIEWS.ADDRESS_QUALIFICATION));
          }
          resolve(response);
        })
        .catch((error) => {
          dispatch(hideLoader());
          logMetrics('FetchFiosProfile', error.message, 'Error');
          if (!isRetry) {
            fiosProfile(dispatch, loopQualApp, notAcp, true);
          }
          reject(error);
        });
    });
  } catch (error) {
    dispatch(hideLoader());
    logMetrics('FetchFiosProfile', error, 'Error');
    if (!isRetry) {
      fiosProfile(dispatch, loopQualApp, notAcp, true);
    }
  }
};

export const cradleInvoke = (dispatch, fromQualification = false, extraParams = {}) => {
  try {
    let flowName = '';
    const location =
      window.location.search &&
      (window.location.search.indexOf('testQualification') > -1 ||
        window.location.search.indexOf('nsa=y') > -1 ||
        window.location.search.indexOf('NSA=Y') > -1)
        ? 'Y'
        : null;
    if (location && location === 'Y') {
      flowName = 'test|';
    }
    const data = { properties: [{ name: 'pageName', value: extraParams?.pageName || 'FWA_SalesLanding' }] };
    let url = apiUrl().cradleRequest;
    if (window.location.host.includes('awssit.ebiz.verizon.com')) {
      url = 'https://wwwawssit.ebiz.verizon.com/inhome/pegaRequest';
    } else if (window.location.host.includes('nte1aws.ebiz.verizon.com')) {
      url = 'https://wwwnte1aws.ebiz.verizon.com/inhome/pegaRequest';
    } else if (window.location.host.includes('nte3aws.ebiz.verizon.com')) {
      url = 'https://wwwnte3aws.ebiz.verizon.com/inhome/pegaRequest';
    } else if (window.location.host.includes('98.verizon.com')) {
      url = 'https://www98.verizon.com/inhome/pegaRequest';
    }
    const requiredflowType = { flowType: flowName };
    return new Promise((resolve, reject) => {
      callingApi(url, MethodType.POST, data, '', requiredflowType)
        .then((response) => {
          const throttleResponse = response.data ? response.data : {};
          if (throttleResponse && throttleResponse.throttleList) {
            window.sessionStorage.setItem('LQCradleTriggered', 'Y');
            window.sessionStorage.setItem('LqThrottle', throttleResponse.throttleList);
          }
          resolve(response);
        })
        .catch((error) => {
          logMetrics('cradleInvoke', error.message, 'Error');
          if (fromQualification) {
            dispatch(hideLoader());
          }
          reject(error);
        });
    });
  } catch (ex) {
    dispatch(hideLoader());
    logMetrics('cradleInvoke', ex.message, 'Error');
    if (fromQualification) {
      dispatch(hideLoader());
    }
  }
};
export const getAddressInfoAgain = async (customerDetails, addressInfo, dispatch) => {
  const fiosStates = ['DE', 'RI', 'CT', 'DC', 'NC', 'VA', 'MD', 'PA', 'NJ', 'NY', 'MA'];
  try {
    return new Promise(async (resolve) => {
      let checkNoStatAddress = false;
      if (customerDetails && fiosStates.indexOf(customerDetails.state) > -1 && !customerDetails.ntasAddrID) {
        logMetrics('ReturnVisitWithLocusFiosState', customerDetails, 'Info');
        checkNoStatAddress = customerDetails?.disprawstr;
      } else if (addressInfo && fiosStates.indexOf(addressInfo.state) > -1 && !addressInfo.ntasAddrID) {
        logMetrics('ReturnVisitWithDirectApiFiosState', addressInfo, 'Info');
        checkNoStatAddress = addressInfo?.lqAddress;
      }
      if (checkNoStatAddress) {
        const results = await getStreetAddress(checkNoStatAddress);
        if (results?.[0]?.addressClassification === 'SFU' || results?.[0]?.addressClassification === 'SBU') {
          logMetrics('RefetchTypeAheadSFU', results?.[0], 'Info');
          resolve(results);
        } else {
          const MDUScenario =
            results?.[0] &&
            fiosStates.indexOf(addressInfo.state) > -1 &&
            (results?.[0]?.addressClassification === 'MDU' || results?.[0]?.addressClassification === 'CJU') &&
            addressInfo.addressLine2 &&
            addressInfo.ntasAddrID;
          if (MDUScenario) {
            const unitInfo = {
              location_id: '',
              ntasAddrId: addressInfo.ntasAddrID,
              sublocation: addressInfo.addressLine2,
            };
            dispatch(onUnitSelection(unitInfo));
            logMetrics('RefetchTypeAheadMDU', results?.[0], 'Info');
            resolve({ isUnit: true, unitInfo });
          } else {
            resolve(null);
            logMetrics('RefetchTypeAhead', results?.[0], 'Info');
          }
        }
      } else {
        if (customerDetails) {
          logMetrics('ReturnVisitWithLocus', customerDetails, 'Info');
        } else if (addressInfo) {
          logMetrics('ReturnVisitWithDirectApi', addressInfo, 'Info');
        }
        resolve(null);
      }
    });
  } catch (e) {
    // resolve(null);
    return new Promise((resolve) => {
      resolve(null);
    });
  }
};
